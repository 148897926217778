import React from "react";
import Layout from "../components/layout-contents";
import styles from "../styles/review.module.css";
import Container from "../components/container";
import { graphql } from "gatsby";

const User = (props) => (
  <div className={styles.topUser}>
    <div className={styles.descUser}>
      <img
        src="/images/about/imok/imok_logo.png"
        className={styles.logoUser}
        alt=""
      />
      <p className={styles.excerpt}>
      Friulian sound artist who brings back to life dead speakers. In his intense live shows tradition and
blasphemy converge. He is also fond of doors and hinges, so he made an album out of them.
      </p>
    </div>
    <a href={props.file} target="_blank" rel="noreferrer" style={{ display: "contents" }}>
      <img
        src="/images/about/imok/imok_img.png"
        className={styles.imageUser}
        alt=""
      />
    </a>
  </div>
);

const Post = (props) => (
  <div className={styles.review}>
    <div className={styles.logodesc}>
      <img
        src={props.logo}
        className={styles.logo}
        alt=""
      />
      <div style={{ textAlign: "center" }} className={styles.description}>
        <p style={{ float: "right" }} className={styles.excerpt}>{props.excerpt}</p>
      </div>
    </div>
    <div>
      <a href={props.link} target="_blank" rel="noreferrer" style={{ display: "contents" }}>
        <img src={props.image} className={styles.image} alt="" />
      </a>
    </div>
  </div>
);

export default ({ data }) => (
  <Layout>
    <Container>
      <User></User>
      <iframe style={{border: 0, width: '100%', height: '120px'}} src="https://bandcamp.com/EmbeddedPlayer/album=2327638467/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/" seamless><a href="https://codafanzine.bandcamp.com/album/porte-e-cardini">Porte e Cardini by imok</a></iframe>
    </Container>
  </Layout>
);